type Convention = {
  date: string,
  location?: string,
  title: string,
  link: string,
}

const conventions: Convention[] = [
  // {
  //   date: '16-17 November 2024',
  //   location: 'Harrogate',
  //   title: 'Thought Bubble Festival',
  //   link: 'https://www.thoughtbubblefestival.com/comicconvention',
  // },
  {
    date: '29 March 2025',
    location: 'Old Spitalfields Market, London',
    title: 'Urban Makers',
    link: 'https://oldspitalfieldsmarket.com/events/guest-market-urban-makers'
  },
  {
    date: '7 June 2025',
    location: 'Boxpark Croydon, London',
    title: 'The Big Market',
    link: 'https://www.instagram.com/thebigmarketlondon/'
  },
  {
    date: '5-6 July',
    location: 'Olympia London',
    title: 'London Film & Comic Con',
    link: 'https://londonfilmandcomiccon.com'
  },
  {
    date: '1-31 July 2025',
    // location: 'Online',
    title: 'LDC Online Comics Fair',
    link: 'https://ldcomics.com/ldcomics-online-fair-2025/'
  },
  {
    date: '28-29 September 2025',
    location: 'Bowness-on-Windermere',
    title: 'The Lakes International Comic Art Festival',
    link: 'https://www.comicartfestival.com/'
  },
  {
    date: '15-16 November 2025',
    location: 'Harrogate',
    title: 'Thought Bubble Comic Convention',
    link: 'https://www.thoughtbubblefestival.com/comicconvention'
  },
];

export default conventions;
